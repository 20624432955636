<template>
<div>
    <my-header></my-header>
    <div class="box">
        <div class="box_A">
            <img src="http://q5355.bvimg.com/10063/54d56adf71632252.png" alt="" class="img">
            <span class="box_span">价格行情</span>
               
    <div class="postion-box">   
        <div class="header-box">
          <div class="header-box-left">
            <div class="header-box-left-center" @mouseenter="Cityshow = true" @mouseleave="Cityshow = false">
              <div >
                <i class="el-icon-location" ></i>
                <span v-if="citys">{{ citys }}</span>
              </div>
              <city-cascade
                @getCity="getCity"
                v-if="Cityshow"
                :style="{ position: 'absolute', top: '42px' ,left:'30%' , color:'green'}"
              ></city-cascade>
              
            </div>
            <div class="header-box-right-center">
                <el-input v-model="queryInfo.productName" :style="{ width:'70%'}" placeholder="请输入品名"></el-input>
                <el-date-picker
                    v-model="value1"
                    value-format="yyyy-MM-dd"
                    @change="pickerTime"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
                <el-button class="header-box-right-center-button" type="primary" @click="searchGood(queryInfo.productName)">查询</el-button>
            </div>
          </div>
        </div>
    </div> 
        </div>
        <div class="boxs">
            <div class="left">
                <img src="../assets/images/title_item.png" alt="">
                <ul class="left_index" >
                    <li v-for="(item,index) in list" :key="index" @click="selcet(index)" >
                        {{item}}
                    </li>
                </ul>
            </div>
            <div class="right">
                <div class="right_box">{{values}}-价格展示</div>
                <div class="time">{{time}}</div> 
                
                <el-table
                :data="tableData"
                style="width: 100%"
                :key="reflush">
                <el-table-column
                    prop="type"
                    label="分类"
                    
                    align="center">
                    <template slot-scope="scope">
              <div v-if="scope.row.type=='1'"> 
                  <span>蔬菜类</span>
              </div>
               <div v-if="scope.row.type=='2'"> 
                  <span>果品类</span>
              </div>
            <div v-if="scope.row.type=='3'"> 
                  <span>水产</span>
              </div>
               <div v-if="scope.row.type=='4'"> 
                  <span>畜禽蛋品</span>
              </div>
            <div v-if="scope.row.type=='5'"> 
                  <span>粮油</span>
              </div>
               <div v-if="scope.row.type=='6'"> 
                  <span>副食</span>
              </div>
            </template>
                </el-table-column>
                <el-table-column
                    prop="productName"
                    label="品名"
                    width="150"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="areaName"
                    label="产地"
                    width="150"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="model"
                    label="规格"
                    width="100"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="unit"
                    label="单位"
                    width="100"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="maxPrice"
                    label="最高"
                    width="100"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="minPrice"
                    label="最低"
                    width="100"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="avgPrice"
                    label="均价"
                    width="100"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="createTime"
                    label="发布日期"
                    width="100"
                    align="center">
                </el-table-column>
                
                </el-table>
                <div class="boxss">
                    <el-pagination
                    background
                    layout="prev, pager, next"
                     @size-change="handleSizeChange"
                    :page-size="pageSize"
                    :current-change="handleCurrentChange"
                    @current-change="handleCurrentChange"
                    :total="total"
                    >
                    </el-pagination>
                </div>
                
            
            </div>
            
        </div>
        
    </div>
</div>
</template>
<script>
import cityCascade from '../components/cascade/cityCascade';
export default{
inject: ["reload"],
data(){
  return{
      queryInfo: {
        type:"",
        city:"",
        beginTime:"",
        productName:"",
        overTime:"",
        pageNum: 1,
        pageSize: 10,
        orderBy:"create_time desc",
      },
          tableData:[],
          list:['蔬菜类','果品类','水产','畜禽蛋品','粮油','副食'],
          reflush:"",
          values:'今日',
          time:'',//当前时间
          Cityshow:false,
          citys:"",
          index:"",
          total:0,
          pageSize:5,
          pageNum: 1,
          beginTime:"",
            type: '',// 分类
            productName: '',//品名
            city: '', //发布地
            model: '',//规格
            unit: '', //单位
            maxPrice: '',//最高
            minPrice: '',//最低
            avgPrice: '',//均价
            createTime: '',//发布日期
            value1:"",
  };
},
watch: {
    "$store.state.checkeds"(info) {
      sessionStorage.setItem("checkeds", info);
      if (JSON.parse(sessionStorage.getItem("checkeds"))) {
        this.citys = JSON.parse(sessionStorage.getItem("checkeds"))[1];
      }
    },
  },
methods:{
    pickerTime(val){
        this.value1 = val
        console.log(val[0],"===="+val[1]);
    },
    //时间搜索
    async searchGood(e){
        console.log(e)
        console.log(this.value1[1])
        
        let api =
        "&pageSize=" +
        this.pageSize +
        "&pageNum=" +
        this.pageNum ;
        const res = await this.$http({
          method: "GET",
          url: `front/market/get?${api}&beginTime=${this.value1[0]}&overTime=${this.value1[1]}&type=${this.index}&city=${this.queryInfo.city}&productName=${e}`,
        });
        if (res.status == 200) {
          this.tableData = res.data.data.list;
          this.total = res.data.data.total
        }
        
        
    },
    //下拉框
     selcet(e){
        console.log(this.list[e])
        this.values = this.list[e];
        this.index = e+1;
        this.searchGood();
    },
    //最新时间
    date(){
        let nowDate = new Date();
        let date = {
            year:nowDate.getFullYear(),
            month:nowDate.getMonth() + 1,
            date:nowDate.getDate()
        };
        let newmonth = date.month > 10 ? date.month : '0' + date.month;
        let newday = date.date > 10 ? date.date : '0' + date.date;
        this.time = date.year + '-' + newmonth + '-' + newday;
        console.log(this.time)
    },
    // 获取选择城市模块中的信息
    getCity(info, lable, bool) {
      console.log('---'+info,"===="+ lable, bool);
      this.queryInfo.city = info[1];
      console.log(info[1])
      // 隐藏级联选择框
      this.Cityshow = false;
      // 显示状态为选中城市
      this.citys = lable[1];
      sessionStorage.setItem("checkeds", JSON.stringify(lable));
      sessionStorage.setItem("addressProvince", JSON.stringify(info[0]));
      sessionStorage.setItem("addressCity", JSON.stringify(info[1]));
      this.$store.commit("updatecheckeds", sessionStorage.getItem("checkeds"));
    
    // this.searchGood();
    this.Regional();
    },
    activated() {
    this.$store.commit("updatecheckeds", sessionStorage.getItem("checkeds"));
  },
  handleCurrentChange(e){
        this.pageNum = e
        this.searchGood();
    },
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.searchGood();
    },
    //渲染页面
    async rendering(){
        const res = await this.$http({
            method:'GET',
            url:`/front/market/get?pageNum=${this.pageNum}&pageSize=${this.pageSize}&overTime=${this.time}&orderBy=${this.queryInfo.orderBy}`,
        });
       
        if(res.status == 200){
            this.tableData = res.data.data.list;
            this.total = res.data.data.total;
        }
        console.log(this.tableData);
    },
    //地区定位---只显示当天的行情
    async Regional(){
        this.queryInfo.city = sessionStorage.getItem("addressCity")
        const res = await this.$http({
            method:'GET',
            url:`/front/market/get?pageNum=${this.pageNum}&pageSize=${this.pageSize}&city=${this.queryInfo.city}`,
        });
       
        if(res.status == 200){
            this.tableData = res.data.data.list;
            this.total = res.data.data.total;
        }
        console.log(this.tableData);
    }
},
created(){
    this.date();
    this.selcet(0);
    this.rendering();
    //   location.reload();
},
components:{
    cityCascade
},
}
</script>
<style lang='scss' scoped>

.box{
    width: 100%;
    margin-top: 100px;
}
.box .img{
    width: 100%;
    height: 450px;
    margin-bottom: 10px;
    
}
.box_A{
    width: 100%;
    text-align: center;
}
.box > .box_A > .box_span{
    font-size: 40px;
    color: #666;
}
.boxs{
    width: 70%;
    height: 500px;
    margin: auto;
   
    margin-bottom: 30px;
}
.boxss{
    text-align: center;
     margin-bottom: 20px;
     margin-top:20px
}
.left{
    width: 18%;
    background: rgb(250, 249, 249);
    float: left;
    padding: 30px 10px;
    text-align: center;
}
.left img{
    width: 80%;
    height: 60px;
}
.left_index li{
    font-size: 20px;
    margin: 30px 0;
    cursor: pointer;
    text-align: center;
}
.right{
    width: 78%;
    float: right;
    border: 1px solid rgb(202, 198, 198);
}
.right_box{
    font-size: 30px;
    text-align: center;
    margin-top: 30px;
}
.time{
    margin-bottom: 20px;
    text-align: center;
    color: #999;
    margin-top: 10px;
}
.back{
    
    font-size: 20px;
    margin-left: 300px;
}
.header-box{
    width: 100%;
    height: 30px;
 
}
.header-box-left {
  z-index: 8;
  
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 63px;
  width: 89%;
  position: relative;
  & > div:nth-child(1) {
    user-select: none;
    cursor: pointer;
    
    & > div:nth-child(1) {
      i {
        color: #131111;
        font-size: 16px;
        position: relative;
        top: 2px;
      }
    }
  }
  & > div:nth-child(2) {
    user-select: none;
  }
}
.header-box-left-center{
    float: left;
    width: 100px;
    height: 20px;
    margin-left: 35%;
}
.header-box-right-center{
    float: right;
}
.header-box-right-center-button{
    margin-left: 10px;
}
</style>